import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import React, {useEffect, useRef, useState} from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import {Click, Wrap} from "@prisma/client"
import {INTERSTITIAL_DELAY, WrapIncludes} from "@wildeye/shared"
import {useLazyGetClickQuery, useLazyGetWrapQuery} from "../../store/apps/api/warmlink"
import {useRouter} from "next/router"
import {useTranslation} from "react-i18next"

export interface InterstitialProps {
  type: 'warm' | 'wrap'
}

export default function Interstitial({type}: InterstitialProps) {
  const router = useRouter()
  const {query} = router
  const stop = query.stop === ''
  const {t} = useTranslation()
  const {slugs} = router.query as { slugs: string[] | undefined }
  const code = slugs?.[0]
  const isWrap = type == 'wrap'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [trigger, {data, isLoading, error}] = isWrap ? useLazyGetWrapQuery() : useLazyGetClickQuery()
  const [wrap, setWrap] = useState<WrapIncludes | null>(null)
  const [initialized, setInitialized] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const timerRef = useRef<number | null>(null)

  useEffect(() => {
    setIsClient(true)
  }, [])

  // This loads the wrap/warm data when code is valid
  useEffect(() => {
    if (slugs) {
      trigger(slugs.join('/'))
    }
  }, [slugs, trigger])

  // Delay and redirect when wrap loaded
  useEffect(() => {
    const handleRedirect = async () => {
      try {
        if (initialized && data && !isLoading && !error) {
          const wrap = data as unknown as Wrap
          setWrap(wrap)
          const click = data as unknown as Click
          const link = isWrap ? wrap.link : click.affiliate_link
          const delay = isWrap ? wrap.delay : INTERSTITIAL_DELAY
          if (!stop) {
            // Replace the current entry in the history stack with the home page URL so that when
            // user goes backwards they end up at Warmlink's home page
            window.history.replaceState(null, '', '/')
            window.history.pushState(null, '', '/')
            // wait for a short time before redirecting to desired location
            timerRef.current = window.setTimeout(async () => {
              // await router.replace(link)
              window.location.href = link
            }, delay)
          } else if (timerRef.current)
            window.clearTimeout(timerRef.current)
        }
      } catch (err) {
        console.error("Error during navigation:", err)
      }
      return
    }

    handleRedirect().then(() => {
    })
  }, [data, isLoading, error, code, router])

  // Redirect to home if code is bad
  useEffect(() => {
    if (!isLoading && (error || !data)) {
      if (initialized) {
        if (!stop) router.push('/').then(() => {
        })
      }
    }
  }, [error, isLoading, data, router, initialized])

// Set initialized when we get valid code
  useEffect(() => {
    if (code !== undefined) {
      setInitialized(true)
    }
  }, [code, query, router])

  function handleVisitWarmlink() {
    if (timerRef.current !== null) {
      window.clearTimeout(timerRef.current)
      router.push(process.env.NEXT_PUBLIC_APP + '')
    }
  }

  if (!isClient) {
    return null
  }

  const click = data as unknown as Click
  const display = stop || isWrap || (data && !isLoading && click.offer_id !== 'warmlink')
  // console.log(`display:${display} code:${code} stop:${stop} data:${JSON.stringify(data)} isLoading:${isLoading}`)
  if (!display)
    return null

  const image = wrap?.user?.image

  return (
    <Stack
      sx={{
        // border: '2px solid #000',
        backgroundColor: 'white',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        textAlign: 'center',
        margin: '0 auto', // Center the box
        gap: 1,
      }}
    >
      {wrap?.user?.pref?.interstitial_avatar && image && <Avatar
        src={image}
        sx={{
          width: 'clamp(40px, 8vw, 100px)',
          height: 'clamp(40px, 8vw, 100px)',
          border: '1px solid orange', // Border size and color
          '@media (max-height: 500px)': {
            display: 'none', // Hide avatar if the height is less than 500px
          }
        }}
      />}
      <Box
        sx={{
          // border: '2px solid #000', // 2px width, solid style, black color
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 0.25,
          mb: 0, mt: 0,
          width: '80%'
        }}
      >
        <img
          src='https://storage.googleapis.com/warmlinkprod/warmlink.io-logo.svg'
          alt='warmlink logo'
          style={{width: '80%'}}
        />
        <img
          src='/images/iconify-svg/svg-spinners--bars-fade.svg'
          alt="spinner"
          style={{width: '16%', marginBottom: -3}} // Match height with logo
        />
      </Box>
      <img
        src='https://storage.googleapis.com/warmlinkprod/wraplink-logos-banner.jpg'
        alt='logo'
        style={{width: '80%'}} // Make banner responsive
      />
      <Box
        sx={{
          // border: '2px solid #eee', // 2px width, solid style, black color
          backgroundColor: '#fafafa',
          alignItems: "center",
          justifyContent: "center",
          width: '80%',
          pt: 2,
          mt: 1,
        }}
      >
        <Typography sx={{
          fontSize: {
            xs: '5vw',                // Default font size for mobile (small screens)
            sm: '4vw',                // Default font size for slightly larger screens
            md: '4vw',                // Font size for medium screens
          },
          '@media (orientation: landscape)': {
            fontSize: '4vw',          // Font size for landscape orientation
          },
          '@media (orientation: portrait)': {
            fontSize: '4vw',          // Font size for portrait orientation
          },
          color: 'black',
          lineHeight: 1.2,
          mt: 0,
          width: '90%',       // Constrain width to 80% of the parent container
          marginLeft: 'auto',       // Center the component horizontally
          marginRight: 'auto',      // Center the component horizontally
          textAlign: 'center', // Optional: center-align the text
          whiteSpace: 'pre-line',
        }}>
          {t("wrap tagline1")}
        </Typography>
        <Typography sx={{
          fontSize: {
            xs: '5vw',                // Default font size for mobile (small screens)
            sm: '4vw',                // Default font size for slightly larger screens
            md: '4vw',                // Font size for medium screens
          },
          '@media (orientation: landscape)': {
            fontSize: '3vw',          // Font size for landscape orientation
          },
          '@media (orientation: portrait)': {
            fontSize: '3.5vw',          // Font size for portrait orientation
          },
          color: 'black',
          lineHeight: 1.2,
          whiteSpace: 'pre-line',
          mt: 2,
          width: '90%',       // Constrain width to 80% of the parent container
          marginLeft: 'auto',       // Center the component horizontally
          marginRight: 'auto',      // Center the component horizontally
          textAlign: 'center', // Optional: center-align the text
        }}>
          {t("wrap tagline2")}
        </Typography>
        <Typography sx={{
          fontSize: {
            xs: '5vw',                // Default font size for mobile (small screens)
            sm: '4vw',                // Default font size for slightly larger screens
            md: '4vw',                // Font size for medium screens
          },
          '@media (orientation: landscape)': {
            fontSize: '2vw',          // Font size for landscape orientation
          },
          '@media (orientation: portrait)': {
            fontSize: '2.5vw',          // Font size for portrait orientation
          },
          width: '80%',
          mt: 2,
          pb: 2,
          marginLeft: 'auto',       // Center the component horizontally
          marginRight: 'auto',      // Center the component horizontally
          textAlign: 'center',
          color: 'black',
        }}>
          {t('wrap redirected')}
        </Typography>
        {wrap?.user?.pref?.interstitial_link &&
          <Button color='success' size='small' variant='contained' onClick={handleVisitWarmlink} sx={{mb: 2}}>
            Visit Warmlink
          </Button>}
      </Box>
    </Stack>
  )
}
